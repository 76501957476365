import { Avatar, Space, Tag, Typography } from "antd";
import { Link } from "react-router-dom";
import { SvgIcon } from "../../../assets/icons";
import { CustomPopconfirm } from "../../../components/custom-popconfirm/CustomPopconfirm";
import { STATUS_COLORS } from "../../../utils/constant";

export const useTable = () => {
  const columns = [
    {
      title: <Typography.Text>ID</Typography.Text>,
      key: "id",
      width: 100,
      align: "center",
      render: (_, record) => {
        return <Typography.Text>{record?.id}</Typography.Text>;
      },
    },
    {
      title: <Typography.Text>Thumb</Typography.Text>,
      key: "title",
      align: "center",
      width: 150,
      render: (_, record) => {
        console.log(record);
        return <Avatar src={record?.thumb?.url} size={40} />;
      },
    },
    {
      title: <Typography.Text>Name</Typography.Text>,
      key: "domain",
      width: "30%",
      render: (_, record) => {
        return <Typography.Text>{record?.name ?? "- - "}</Typography.Text>;
      },
    },
    {
      title: <Typography.Text>Status</Typography.Text>,
      key: "status",
      align: "center",
      render: (_, record) => {
        return <Tag color={STATUS_COLORS[record?.status]}>{record?.status?.toUpperCase()}</Tag>;
      },
    },
    {
      title: <Typography.Text>Publish Schedule</Typography.Text>,
      key: "status",
      align: "center",
      render: (_, record) => {
        return <Typography.Text>{record?.publish_schedule ?? "- - "}</Typography.Text>;
      },
    },
    {
      key: "action",
      align: "center",
      width: 150,
      render: (_, record) => {
        return (
          <Space size="middle">
            <Link to={`/product-sync-task/${record?.id}`}>
              <SvgIcon.IconEdit />
            </Link>
            <CustomPopconfirm
              title="Delete"
              component={<SvgIcon.IconDelete />}
              description="Are you sure to delete this?"
            />
          </Space>
        );
      },
    },
  ];
  return { columns };
};
