import { SearchOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { SvgIcon } from "../../../assets/icons";
import { CustomButton } from "../../../components/custom-button/CustomButton";
import { CustomCountSelected } from "../../../components/custom-count-selected/CustomCountSelected";
import { CustomInput } from "../../../components/custom-input/CustomInput";
import { CustomPagination } from "../../../components/custom-pagination/CustomPagination";
import { CustomSelect } from "../../../components/custom-select/CustomSelect";
import { CustomTable } from "../../../components/custom-table/CustomTable";
import { useIsWidthMobile } from "../../../hooks/apps/useIsWidthMobile";
import { useList } from "../../../hooks/sales-channel/list-sales-channel/useList";
import { useTable } from "../../../hooks/sales-channel/list-sales-channel/useTable";
import { CustomListPage } from "../../../layouts/custom-list-page/CustomListPage";
import { optionsOrderby } from "../../../utils/options";
import { CreateSalesChannel } from "./widget/CreateSalesChannel";
import { ImportSalesChannel } from "./widget/ImportSalesChannel";

export const ListSalesChannelSection = () => {
  const {
    salesChannels,
    loading,
    paramsSalesChannel,
    selectedRowKeys,
    paginationData,
    valuesSearch,
    refetch,
    handleDeleteSalesChannel,
    handleDeleteSelectedSalesChannel,
    onSelectChange,
    handleSearchSalesChannel,
    handleSortOrder,
    handleChangePerPage,
    handleChangePage,
  } = useList();

  const { columns } = useTable({ handleDeleteSalesChannel });
  const isWidthMobile = useIsWidthMobile();

  return (
    <CustomListPage
      title="Sales Channel"
      headerCard={
        <div className="flex-column">
          <div className="flex justify-between gap-2 flex-wrap">
            <ImportSalesChannel />
            <div className="flex gap-2">
              <CreateSalesChannel refetch={refetch} />
              <CustomButton
                title="Delete Selected"
                type="cancel"
                onClick={() => handleDeleteSelectedSalesChannel()}
                icon={<SvgIcon.IconDelete className="w-[18px] text-[#e7515a]" />}
              />
            </div>
          </div>
          <Row className="flex-center-between">
            <Col span={isWidthMobile ? 24 : 16} className={`${isWidthMobile ? "mb-2" : ""}`}>
              <CustomInput
                placeholder="Search sales channel"
                prefix={<SearchOutlined />}
                value={valuesSearch}
                onChange={(value) => handleSearchSalesChannel(value)}
              />
            </Col>
            <Col span={isWidthMobile ? 24 : 4}>
              <CustomSelect
                options={optionsOrderby}
                defaultValue={paramsSalesChannel.sort_order}
                width="100%"
                onChange={(value) => handleSortOrder(value)}
              />
            </Col>
          </Row>
          {selectedRowKeys?.length > 0 && <CustomCountSelected count={selectedRowKeys?.length} />}
        </div>
      }
      contentCard={
        <>
          <CustomTable
            columns={columns}
            data={salesChannels}
            rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }}
            loading={loading}
            rowKey={(record) => record.id}
          />
          {salesChannels?.length > 0 && (
            <CustomPagination
              total={paginationData?.total ?? 0}
              limit={paginationData?.limit ?? 0}
              page={paginationData?.page ?? 0}
              handleChangePage={(pageNumber) => handleChangePage(pageNumber)}
              onChangePerPage={(pageSize) => handleChangePerPage(pageSize)}
            />
          )}
        </>
      }
    />
  );
};
