/* eslint-disable react-hooks/exhaustive-deps */
import { Form } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { notifySuccess } from "../../../components/custom-notify/CustomNotify";
import { getURLProduct, postFileProduct, uploadsProduct } from "../../../services/productService";
import { PARAMS_GET_MAX } from "../../../utils/constant";
import { readFileAsBinary, useDebounce } from "../../../utils/helperFuncs";

export const useUploadCsv = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paramsCategory, setParamsCategory] = useState(PARAMS_GET_MAX);
  const [valueSearchCategory, setValueSearchCategory] = useState("");

  const debouncedQueryCategory = useDebounce(valueSearchCategory);

  const handleUploadFile = async (info) => {
    let newFileList = [...info?.fileList];
    setFileList(newFileList);
  };
  const handleSearchCategory = (value) => {
    setValueSearchCategory(value);
  };

  const handleUploadProduct = async () => {
    await form
      .validateFields()
      .then(async () => {
        if (fileList?.length > 0) {
          setLoading(true);
          const binaryFiles = await Promise.all(fileList.map((file) => readFileAsBinary(file.originFileObj)));
          const formData = form.getFieldsValue();
          await getURLProduct({
            file_name: fileList[0]?.name,
          })
            .then(async (res) => {
              const post_url = res?.data?.post_url;
              const file_url = res?.data?.file_url;
              await postFileProduct({ baseURL: post_url, binaryFiles: binaryFiles }).then(async (res) => {
                await uploadsProduct({
                  url: file_url,
                  category_ids: formData?.category_ids,
                })
                  .then((res) => {
                    navigate("/products");
                    notifySuccess(res?.message);
                  })
                  .catch(() => {
                    notifySuccess(res?.error);
                  });
              });
            })
            .finally(() => {
              setLoading(false);
            });
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    setParamsCategory({ ...paramsCategory, query: valueSearchCategory });
  }, [debouncedQueryCategory]);

  return {
    form,
    fileList,
    loading,
    handleSearchCategory,
    setParamsCategory,
    handleUploadFile,
    handleUploadProduct,
  };
};
