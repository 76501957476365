import { SearchOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { CustomInput } from "../../../components/custom-input/CustomInput";
import { CustomPagination } from "../../../components/custom-pagination/CustomPagination";
import { CustomSelect } from "../../../components/custom-select/CustomSelect";
import { CustomTable } from "../../../components/custom-table/CustomTable";
import { useIsWidthMobile } from "../../../hooks/apps/useIsWidthMobile";
import { useTable } from "../../../hooks/medium-account/list-medium-account/useTable";
import { CustomListPage } from "../../../layouts/custom-list-page/CustomListPage";
import { SORT_ORDER } from "../../../utils/constant";
import { optionsOrderby } from "../../../utils/options";
import { AddAccount } from "./widget/AddAccount";
import { UploadCsv } from "./widget/UploadCsv";

export const ListMediumAccountSection = () => {
  const { columns } = useTable();
  const isWidthMobile = useIsWidthMobile();

  return (
    <CustomListPage
      title="Medium Account"
      headerCard={
        <div className="flex-column">
          <div className="flex justify-end gap-2">
            <UploadCsv />
            <AddAccount />
          </div>
          <Row className="flex-center-between">
            <Col span={isWidthMobile ? 24 : 16} className={`${isWidthMobile ? "mb-2" : ""}`}>
              <CustomInput placeholder="Search medium account" prefix={<SearchOutlined />} />
            </Col>
            <Col span={isWidthMobile ? 24 : 4} className="flex justify-end gap-2">
              <CustomSelect options={optionsOrderby} value={SORT_ORDER.DESC} width="100%" />
            </Col>
          </Row>
        </div>
      }
      contentCard={
        <>
          <CustomTable columns={columns} data={[{}]} rowKey={(record) => record?.id} />
          <CustomPagination total={0} limit={25} page={1} handleChangePage={() => {}} onChangePerPage={() => {}} />
        </>
      }
    />
  );
};
