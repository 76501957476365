import { Form, Row } from "antd";
import { SvgIcon } from "../../../../assets/icons";
import { CustomButton } from "../../../../components/custom-button/CustomButton";
import { CustomDateTimePicker } from "../../../../components/custom-date-time-picker/CustomDateTimePicker";
import { CustomPopover } from "../../../../components/custom-popover/CustomPopover";
import { CustomNameFormItem } from "../../../../components/custom-title/CustomNameFormItem";
import { useIsWidthMobile } from "../../../../hooks/apps/useIsWidthMobile";
import { useFilter } from "../../../../hooks/products/list-product/useFilter";

export const FilterWidget = () => {
  const { form, visiblePopover, initialValues, setVisiblePopover, handleClearFilter, handleFilter } = useFilter({});
  const isWidthMobile = useIsWidthMobile();

  return (
    <Form form={form} initialValues={initialValues}>
      <CustomPopover
        width={isWidthMobile ? 300 : 400}
        open={visiblePopover}
        onClose={() => setVisiblePopover(false)}
        component={
          <CustomButton
            title="Filter"
            type="default"
            onClick={() => {
              setVisiblePopover(true);
            }}
            icon={<SvgIcon.IconFilter className="w-5 text-[#4361ee]"></SvgIcon.IconFilter>}
          />
        }
        title={
          <>
            <div>Filter</div>
            <div className="flex gap-2">
              <CustomButton title="Clear" height={32} onClick={() => handleClearFilter()} />
              <CustomButton title="Save" type="primary" height={32} onClick={() => handleFilter()} />
            </div>
          </>
        }
        content={
          <Row>
            <CustomNameFormItem title="Creation Date" />
            <div className="flex-center-between gap-2">
              <Form.Item name="created_at_from" className="w-full">
                <CustomDateTimePicker placeholder="Select from date" />
              </Form.Item>
              <Form.Item name="created_at_to" className="w-full">
                <CustomDateTimePicker placeholder="Select to date" />
              </Form.Item>
            </div>
          </Row>
        }
      />
    </Form>
  );
};
