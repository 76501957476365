import { ReactComponent as IconBack } from "./icon-back.svg";
import { ReactComponent as IconCatalogs } from "./icon-catalogs.svg";
import { ReactComponent as IconChangePassword } from "./icon-change-password.svg";
import { ReactComponent as IconCollapseFalse } from "./icon-collapse-false.svg";
import { ReactComponent as IconCollapseTrue } from "./icon-collapse-true.svg";
import { ReactComponent as IconCopy } from "./icon-copy.svg";
import { ReactComponent as IconCreate } from "./icon-create.svg";
import { ReactComponent as IconDelete } from "./icon-delete.svg";
import { ReactComponent as IconEdit } from "./icon-edit.svg";
import { ReactComponent as IconExport } from "./icon-export.svg";
import { ReactComponent as IconFilter } from "./icon-filter.svg";
import { ReactComponent as IconImportFile } from "./icon-import-file.svg";
import { ReactComponent as IconImport } from "./icon-import.svg";
import { ReactComponent as IconLogout } from "./icon-logout.svg";
import { ReactComponent as IconMenuCategories } from "./icon-menu-categories.svg";
import { ReactComponent as IconMenuDescription } from "./icon-menu-description.svg";
import { ReactComponent as IconMenuMediumAccount } from "./icon-menu-medium-account.svg";
import { ReactComponent as IconMenuMediumPost } from "./icon-menu-medium-post.svg";
import { ReactComponent as IconMenuProductSyncTask } from "./icon-menu-product-sync-task.svg";
import { ReactComponent as IconMenuProduct } from "./icon-menu-product.svg";
import { ReactComponent as IconMenuSalesChannel } from "./icon-menu-sales-channel.svg";
import { ReactComponent as IconMenuSyncTask } from "./icon-menu-sync-task.svg";
import { ReactComponent as IconMenuUser } from "./icon-menu-users.svg";
import { ReactComponent as IconProfile } from "./icon-profile.svg";
import { ReactComponent as IconRight } from "./icon-right.svg";
import { ReactComponent as IconSync } from "./icon-sync.svg";

const SvgIcon = {
  IconDelete,
  IconEdit,
  IconImportFile,
  IconMenuUser,
  IconLogout,
  IconChangePassword,
  IconProfile,
  IconFilter,
  IconCreate,
  IconImport,
  IconSync,
  IconBack,
  IconCatalogs,
  IconRight,
  IconMenuDescription,
  IconMenuCategories,
  IconMenuSalesChannel,
  IconMenuProduct,
  IconMenuSyncTask,
  IconCollapseFalse,
  IconCollapseTrue,
  IconExport,
  IconMenuMediumPost,
  IconMenuMediumAccount,
  IconMenuProductSyncTask,
  IconCopy,
};

export { SvgIcon };
