export const REACT_APP_VERSION_APP = "1.1.1";

export const LABEL_WEBSITE_APP = "SPG Manager";

export const ACCEPT_FILE_TYPES_ZIP = [".zip"];

export const ACCEPT_FILE_TYPES_IMAGE = "image/*";

export const ACCEPT_FILE_TYPES_CSV = ".csv";

export const MAX_PER_PAGE = 250;

export const SORT_ORDER = {
  ASC: "asc",
  DESC: "desc",
};

export const SORT_BY = {
  CREATED_AT: "created_at",
  ID: "id",
};

export const ROLE_USERS = {
  ADMIN: "admin",
  STAFF: "staff",
  SUPER_ADMIN: "super_admin",
};

export const PLATFORM = {
  WOO_COMMERCE: "WooCommerce",
  SHOPIFY: "SHOPIFY",
};

export const SALES_CHANEL_STATUS = {
  PUBLISHED: "PUBLISHED",
  UNPUBLISHED: "UNPUBLISHED",
};

export const TYPE_SELECTION = {
  SELECTED: "SELECTED",
  ALL: "ALL",
};

export const ACCOUNT_STATUS = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
};

export const TIME_PUBLISH = {
  NOW: "NOW",
  SELECT_TIME: "SELECT_TIME",
};

export const PARAMS_DEFAULT = {
  page: 1,
  per_page: 25,
  sort_order: SORT_ORDER.DESC,
  sort_by: SORT_BY.ID,
};

export const PARAMS_LIMIT = {
  page: 1,
  limit: 50,
  sort_order: SORT_ORDER.DESC,
  sort_by: SORT_BY.ID,
};

export const PARAMS_GET_MAX = {
  page: 1,
  per_page: 500,
  sort_order: SORT_ORDER.DESC,
  sort_by: SORT_BY.ID,
};

export const SYNC_TASK_STATUS = {
  DRAFT: "draft",
  PROCESSING: "processing",
  COMPLETED: "completed",
  CANCELED: "canceled",
  PAUSED: "paused",
};

export const SC_PRODUCT_STATUS = {
  PENDING: "pending",
  INQUEUE: "inqueue",
  SUCCESS: "success",
  FAILED: "failed",
  CANCELED: "canceled",
  PAUSED: "paused",
  DELETED: "deleted",
};

export const STATUS_COLORS = {
  [SYNC_TASK_STATUS.DRAFT]: "geekblue",
  [SYNC_TASK_STATUS.PROCESSING]: "orange",
  [SYNC_TASK_STATUS.COMPLETED]: "green",
  [SYNC_TASK_STATUS.CANCELED]: "red",
  [SYNC_TASK_STATUS.PAUSED]: "gold",
  [SC_PRODUCT_STATUS.PENDING]: "orange",
  [SC_PRODUCT_STATUS.INQUEUE]: "lime",
  [SC_PRODUCT_STATUS.SUCCESS]: "green",
  [SC_PRODUCT_STATUS.FAILED]: "red",
  [SC_PRODUCT_STATUS.CANCELED]: "gold",
  [SC_PRODUCT_STATUS.PAUSED]: "geekblue",
  [SC_PRODUCT_STATUS.DELETED]: "red",
  [ACCOUNT_STATUS.ACTIVE]: "green",
  [ACCOUNT_STATUS.INACTIVE]: "red",
};

export const ROLE_COLOR = {
  [ROLE_USERS.ADMIN]: "geekblue",
  [ROLE_USERS.STAFF]: "orange",
  [ROLE_USERS.SUPER_ADMIN]: "red",
};

export const PLATFORM_COLORS = {
  [PLATFORM.WOO_COMMERCE]: "purple",
  [PLATFORM.SHOPIFY]: "green",
};

export const UNIT_TIME = {
  HOUR: "HOUR",
  MINUTE: "MINUTE",
  SECOND: "SECOND",
};

export const WHERE_INSERT_RENAME_RULE = {
  PREFIX: "Prefix",
  SUFFIX: "Suffix",
  POSITION: "Position",
  AFTER_TEXT: "After text",
  BEFORE_TEXT: "Before text",
  REPLACE_CURRENT_NAME: "Replace current name",
};

export const RADIO_REPLACE_RENAME_RULE = {
  ALL: "All",
  FIRST: "First",
  LAST: "Last",
};

export const CHECKBOX_REPLACE_RENAME_RULE = {
  CASE_SENSITIVE: "Case sensitive",
  WHOLE_WORDS_ONLY: "Whole words only",
};

export const CHECKBOX_DELETE_RENAME_RULE = {
  DELETE_CURRENT_NAME: "Delete current name",
  RIGHT_TO_LEFT: "Right to left",
  DO_NOT_REMOVE_DELIMITERS: "Do not removed delimiters",
};

export const TAB_RENAME_RULE = {
  INSERT: "Insert",
  DELETE: "Delete",
  REMOVE: "Remove",
  REPLACE: "Replace",
};

export const DELETE_FROM_RENAME_RULE = {
  POSITION: "Position",
  DELIMITER: "Delimiter",
};

export const DELETE_UNTIL_RENAME_RULE = {
  COUNT: "Count",
  DELIMITER: "Delimiter",
  TILL_THE_END: "Till the end",
};

export const PRODUCT_SYNC_TASK_STATUS = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
};

export const PROXY_HTTP = {
  HTTP: "http",
  HTTPS: "https",
};

export const METHOD_PROXY_HTTP = {
  CREATE: "create",
  UPDATE: "update",
};
