import { Col, Form, Row } from "antd";
import { useState } from "react";
import { SvgIcon } from "../../../../assets/icons";
import { CustomButton } from "../../../../components/custom-button/CustomButton";
import { CustomInput } from "../../../../components/custom-input/CustomInput";
import CustomModal from "../../../../components/custom-modal/CustomModal";
import { notifyError, notifySuccess } from "../../../../components/custom-notify/CustomNotify";
import { CustomSelect } from "../../../../components/custom-select/CustomSelect";
import { CustomNameFormItem } from "../../../../components/custom-title/CustomNameFormItem";
import { createSalesChannel } from "../../../../services/salesChannelService";
import { PLATFORM } from "../../../../utils/constant";
import { optionsPlatform } from "../../../../utils/options";
import { checkRulesNotNull } from "../../../../utils/validator";

export const CreateSalesChannel = ({ refetch }) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const initialValues = {
    platform: PLATFORM.SHOPIFY,
  };

  const handleCreateSalesChannel = () => {
    form
      .validateFields()
      .then(() => {
        const formData = form.getFieldsValue();

        const createData = {
          domain: formData.domain,
          api_key: formData.api_key,
          api_token: formData.api_token,
          platform: formData.platform,
        };

        createSalesChannel(createData)
          .then((res) => {
            refetch();
            notifySuccess(res?.message);
          })
          .catch((error) => {
            notifyError(error?.toString());
          });
      })
      .catch((error) => {});
  };
  return (
    <Form form={form} initialValues={initialValues}>
      <CustomModal
        component={
          <CustomButton
            title="Create Sales Channel"
            icon={<SvgIcon.IconImport className="w-5" />}
            type="primary"
            onClick={() => setVisible(true)}
          />
        }
        title="Create Sales Channel"
        width={550}
        open={visible}
        onClose={() => {
          form.resetFields();
          setVisible(false);
        }}
        onCancel={() => {
          form.resetFields();
          setVisible(false);
        }}
        onOk={() => handleCreateSalesChannel()}
        labelConfirm="Create"
        content={
          <Col span={24} className="flex-column">
            <Row>
              <CustomNameFormItem title="Domain" required />
              <Form.Item name="domain" className="w-full" rules={[checkRulesNotNull()]}>
                <CustomInput placeholder="Enter domain" />
              </Form.Item>
            </Row>
            <Row>
              <CustomNameFormItem title="Api Key" required />
              <Form.Item name="api_key" className="w-full" rules={[checkRulesNotNull()]}>
                <CustomInput placeholder="Enter api key" />
              </Form.Item>
            </Row>
            <Row>
              <CustomNameFormItem title="Api Token" required />
              <Form.Item name="api_token" className="w-full" rules={[checkRulesNotNull()]}>
                <CustomInput placeholder="Enter api token" />
              </Form.Item>
            </Row>
            <Row>
              <CustomNameFormItem title="Platform" required />
              <Form.Item name="platform" className="w-full">
                <CustomSelect
                  options={optionsPlatform}
                  placeholder="Select platform"
                  defaultValue={PLATFORM.SHOPIFY}
                  disabled
                />
              </Form.Item>
            </Row>
          </Col>
        }
      />
    </Form>
  );
};
