import {
  CHECKBOX_DELETE_RENAME_RULE,
  CHECKBOX_REPLACE_RENAME_RULE,
  DELETE_FROM_RENAME_RULE,
  DELETE_UNTIL_RENAME_RULE,
  METHOD_PROXY_HTTP,
  PLATFORM,
  PRODUCT_SYNC_TASK_STATUS,
  PROXY_HTTP,
  RADIO_REPLACE_RENAME_RULE,
  ROLE_USERS,
  SALES_CHANEL_STATUS,
  SC_PRODUCT_STATUS,
  SORT_ORDER,
  SYNC_TASK_STATUS,
  TIME_PUBLISH,
  TYPE_SELECTION,
  UNIT_TIME,
  WHERE_INSERT_RENAME_RULE,
} from "./constant";

export const optionsOrderby = [
  { value: SORT_ORDER.DESC, label: "Newest First" },
  { value: SORT_ORDER.ASC, label: "Oldest First" },
];

export const optionsPlatform = [
  { value: PLATFORM.WOO_COMMERCE, label: "WooCommerce" },
  { value: PLATFORM.SHOPIFY, label: "Shopify" },
];

export const optionsSalesChannelStatus = [
  { value: SALES_CHANEL_STATUS.PUBLISHED, label: "Published" },
  { value: SALES_CHANEL_STATUS.UNPUBLISHED, label: "Unpublished" },
];

export const optionsProductSyncTaskStatus = [
  { value: PRODUCT_SYNC_TASK_STATUS.ACTIVE, label: "Active" },
  { value: PRODUCT_SYNC_TASK_STATUS.INACTIVE, label: "InActive" },
];

export const optionsSelection = [
  { value: TYPE_SELECTION.SELECTED, label: "To selected product" },
  { value: TYPE_SELECTION.ALL, label: "To all from the filtered results" },
];

export const optionUseQuery = {
  refetchOnWindowFocus: true,
  staleTime: 0,
  cacheTime: 0,
  refetchInterval: 0,
};

export const optionsTimePublish = [
  { value: TIME_PUBLISH.NOW, label: "Now" },
  { value: TIME_PUBLISH.SELECT_TIME, label: "Select Time" },
];

export const optionsUnitTime = [
  { value: UNIT_TIME.HOUR, label: "Hour" },
  { value: UNIT_TIME.MINUTE, label: "Minute" },
  { value: UNIT_TIME.SECOND, label: "Second" },
];

export const optionsActiveUsers = [
  { value: true, label: "Active" },
  { value: false, label: "Inactive" },
];

export const optionsRolesUsers = [
  { value: ROLE_USERS.ADMIN, label: "Admin" },
  { value: ROLE_USERS.STAFF, label: "Staff" },
];

export const optionsSyncTaskStatus = [
  { value: SYNC_TASK_STATUS.PROCESSING, label: "Processing" },
  { value: SYNC_TASK_STATUS.CANCELED, label: "Canceled" },
  { value: SYNC_TASK_STATUS.DRAFT, label: "Draft" },
  { value: SYNC_TASK_STATUS.COMPLETED, label: "Completed" },
  { value: SYNC_TASK_STATUS.PAUSED, label: "Paused" },
];

export const optionsStatusScProduct = [
  { value: SC_PRODUCT_STATUS.PENDING, label: "Pending" },
  { value: SC_PRODUCT_STATUS.INQUEUE, label: "Inqueue" },
  { value: SC_PRODUCT_STATUS.SUCCESS, label: "Success" },
  { value: SC_PRODUCT_STATUS.FAILED, label: "Failed" },
  { value: SC_PRODUCT_STATUS.CANCELED, label: "Canceled" },
  { value: SC_PRODUCT_STATUS.PAUSED, label: "Paused" },
  { value: SC_PRODUCT_STATUS.DELETED, label: "Deleted" },
];

export const optionsWhereInsertRenameRule = [
  { value: WHERE_INSERT_RENAME_RULE.PREFIX, label: "Prefix" },
  { value: WHERE_INSERT_RENAME_RULE.SUFFIX, label: "Suffix" },
  { value: WHERE_INSERT_RENAME_RULE.POSITION, label: "Position" },
  { value: WHERE_INSERT_RENAME_RULE.AFTER_TEXT, label: "After text" },
  { value: WHERE_INSERT_RENAME_RULE.BEFORE_TEXT, label: "Before text" },
  { value: WHERE_INSERT_RENAME_RULE.REPLACE_CURRENT_NAME, label: "Replace current name" },
];

export const optionsRightToLeftRenameRule = [{ value: true, label: "Right-to-left" }];

export const optionsRadioReplaceRenameRule = [
  { value: RADIO_REPLACE_RENAME_RULE.ALL, label: "All" },
  { value: RADIO_REPLACE_RENAME_RULE.FIRST, label: "First" },
  { value: RADIO_REPLACE_RENAME_RULE.LAST, label: "Last" },
];

export const optionsCheckboxReplaceRenameRule = [
  { value: CHECKBOX_REPLACE_RENAME_RULE.CASE_SENSITIVE, label: "Case sensitive" },
  { value: CHECKBOX_REPLACE_RENAME_RULE.WHOLE_WORDS_ONLY, label: "Whole words only" },
];

export const optionsInterpretReplaceRenameRule = [
  { value: true, label: "Interpret '?', '*', '[',']' as wildcards and '$n' as backrefrences?" },
];

export const optionsInterpretRemoveRenameRule = [
  { value: true, label: "Interpret symbols '?', '*', '[',']' as wildcards?" },
];

export const optionsCheckboxDeleteRenameRule = [
  { value: CHECKBOX_DELETE_RENAME_RULE.DELETE_CURRENT_NAME, label: "Delete current name" },
  { value: CHECKBOX_DELETE_RENAME_RULE.RIGHT_TO_LEFT, label: "Right to left" },
  { value: CHECKBOX_DELETE_RENAME_RULE.DO_NOT_REMOVE_DELIMITERS, label: "Do not remove delimiters" },
];

export const optionsDeleteFromRenameRule = [
  { value: DELETE_FROM_RENAME_RULE.POSITION, label: "Position" },
  { value: DELETE_FROM_RENAME_RULE.DELIMITER, label: "Delimiter" },
];

export const optionsDeleteUntilRenameRule = [
  { value: DELETE_UNTIL_RENAME_RULE.COUNT, label: "Count" },
  { value: DELETE_UNTIL_RENAME_RULE.DELIMITER, label: "Delimiter" },
  { value: DELETE_UNTIL_RENAME_RULE.TILL_THE_END, label: "Till the end" },
];

export const optionsProxyHttp = [
  { value: PROXY_HTTP.HTTP, label: "http" },
  { value: PROXY_HTTP.HTTPS, label: "https" },
];

export const optionsMethodProxyHttp = [
  { value: METHOD_PROXY_HTTP.CREATE, label: "create" },
  { value: METHOD_PROXY_HTTP.UPDATE, label: "update" },
];
