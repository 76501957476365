/* eslint-disable no-useless-escape */
import { message } from "antd";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { UNIT_TIME } from "./constant";

//Build query string by params
export const buildQueryString = ({ params }) => {
  if (!params) return;

  let rootUrl = window?.location?.origin + window?.location?.pathname;
  let queryRoot = `${rootUrl}?`;

  const query = queryString.stringify(params, {
    arrayFormat: "index",
    parseBooleans: true,
    parseNumbers: true,
  });

  window?.history?.pushState(null, null, queryRoot + query);
};

export const getQuery = () => {
  const currentUrl = window.location.href;
  const params = new URL(currentUrl)?.searchParams;
  const result = {};

  for (let [key, value] of params?.entries()) {
    const arrayMatch = key?.match(/^(.+)\[(\d+)\]$/);
    const isNumeric = (n) => !isNaN(n) && !isNaN(parseFloat(n));

    if (arrayMatch) {
      const arrayKey = arrayMatch[1];
      const index = arrayMatch[2];

      if (!result[arrayKey]) {
        result[arrayKey] = [];
      }
      result[arrayKey][index] = isNumeric(value) ? Number(value) : value;
    } else {
      result[key] = isNumeric(value) ? Number(value) : value;
    }
  }

  return result;
};

export const useDebounce = (value, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
};

export const convertToTreeData = (data) => {
  return data?.map((item) => ({
    title: item.name,
    key: item.id,
    children: item.children
      ? convertToTreeData(item.children) // Recursive call to handle children
      : [],
  }));
};

export const delayTimeout = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const parseTimeSplitUnit = (timeString) => {
  const [hours, minutes, seconds] = timeString?.split(":")?.map(Number);
  if (hours > 0) {
    return { time: hours, unit: UNIT_TIME.HOUR };
  } else if (minutes > 0) {
    return { time: minutes, unit: UNIT_TIME.MINUTE };
  } else {
    return { time: seconds, unit: UNIT_TIME.SECOND };
  }
};

export const convertToTimeString = (value, unit) => {
  let hours = 0;
  let minutes = 0;
  let seconds = 0;

  switch (unit) {
    case UNIT_TIME.SECOND:
      seconds = value;
      minutes = Math.floor(seconds / 60);
      seconds = seconds % 60;
      hours = Math.floor(minutes / 60);
      minutes = minutes % 60;
      break;
    case UNIT_TIME.MINUTE:
      minutes = value;
      hours = Math.floor(minutes / 60);
      minutes = minutes % 60;
      break;
    case UNIT_TIME.HOUR:
      hours = value;
      break;
    default:
      throw new Error("Unit không hợp lệ");
  }

  const timeString = [
    String(hours).padStart(2, "0"),
    String(minutes).padStart(2, "0"),
    String(seconds).padStart(2, "0"),
  ].join(":");

  return timeString;
};

export const getHostName = (url) => {
  return url.replace(/^https?:\/\//, "");
};

export const ensureHttps = (domain) => {
  if (!domain.startsWith("https://")) {
    domain = "https://" + domain;
  }
  return domain;
};

export const readFileAsBinary = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });
};

export function getFileNameWaterMark(url) {
  const regex = /\/([^\/]+\.(?:png|jpe?g|gif|bmp|tiff?|webp|svg|ico))(?:-|$)/i;
  const match = url.match(regex);
  return match ? match[1] : "";
}

function formatObject(obj) {
  const entries = Object.entries(obj)
    .map(([key, value]) => `${key}: ${typeof value === "string" ? `"${value}"` : value}`)
    .join(", ");
  return `(${entries})`;
}

export const convertRenameConfig = (data) => {
  return Object.entries(data)
    .map(([key, value]) => {
      if (typeof value === "object" && !Array.isArray(value) && value !== null) {
        return Object.keys(value).length === 0 ? `${key}: ""` : `${key}: ${formatObject(value)}`;
      }
      return `${key}: ${typeof value === "string" ? `"${value}"` : value}`;
    })
    .join(", ");
};

export const copyText = (copyText) => {
  if (copyText?.length > 0) {
    navigator.clipboard.writeText(copyText);
    message.success("Copied successfully.");
  } else {
    message.success("Link does not exist");
  }
};
