import { Space, Tag, Typography } from "antd";
import { SvgIcon } from "../../../assets/icons";
import { CustomButton } from "../../../components/custom-button/CustomButton";
import { CustomPopconfirm } from "../../../components/custom-popconfirm/CustomPopconfirm";
import { ACCOUNT_STATUS, STATUS_COLORS } from "../../../utils/constant";
import { copyText } from "../../../utils/helperFuncs";

export const useTable = () => {
  const columns = [
    {
      title: <Typography.Text>ID</Typography.Text>,
      key: "id",
      width: 100,
      align: "center",
      render: (_, record, index) => {
        return <Typography.Text>{index + 1}</Typography.Text>;
      },
    },
    {
      title: <Typography.Text>Username</Typography.Text>,
      key: "title",
      render: (_, record) => {
        return <Typography.Text>Sporttrend</Typography.Text>;
      },
    },
    {
      title: <Typography.Text>Name</Typography.Text>,
      key: "domain",
      render: (_, record) => {
        return <Typography.Text>Sporttrend</Typography.Text>;
      },
    },
    {
      title: <Typography.Text>Token</Typography.Text>,
      key: "status",
      align: "center",
      render: (_, record) => {
        return (
          <CustomButton
            title="Copy Token"
            type="primary"
            icon={<SvgIcon.IconCopy className="w-5" />}
            onClick={() => {
              copyText(
                "https://medium.com/@teetrend/the-hand-of-jesus-lion-and-lamb-hoodie-jesus-is-my-savior-hoodies-jesus-hoodie-christmas-gift-6616c805904c"
              );
            }}
          />
        );
      },
    },
    {
      title: <Typography.Text>Status</Typography.Text>,
      key: "status",
      align: "center",
      render: (_, record) => {
        return <Tag color={STATUS_COLORS[ACCOUNT_STATUS.ACTIVE]}>Active</Tag>;
      },
    },
    {
      key: "action",
      align: "center",
      width: 150,
      render: (_, record) => {
        return (
          <Space size="middle">
            <CustomPopconfirm
              title="Delete"
              component={<SvgIcon.IconDelete />}
              description="Are you sure to delete this?"
            />
          </Space>
        );
      },
    },
  ];
  return { columns };
};
