import { SearchOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { CSVLink } from "react-csv";
import { SvgIcon } from "../../../assets/icons";
import { CustomButton } from "../../../components/custom-button/CustomButton";
import { CustomInput } from "../../../components/custom-input/CustomInput";
import { CustomPagination } from "../../../components/custom-pagination/CustomPagination";
import { CustomSelect } from "../../../components/custom-select/CustomSelect";
import { CustomTable } from "../../../components/custom-table/CustomTable";
import { useIsWidthMobile } from "../../../hooks/apps/useIsWidthMobile";
import { useTable } from "../../../hooks/medium-post/list-medium-post/useTable";
import { CustomListPage } from "../../../layouts/custom-list-page/CustomListPage";
import { SORT_ORDER } from "../../../utils/constant";
import { optionsOrderby } from "../../../utils/options";
import { FilterWidget } from "./widget/FilterWidget";
import { InsertPost } from "./widget/InsertPost";

export const ListMediumPostSection = () => {
  const { columns } = useTable();
  const isWidthMobile = useIsWidthMobile();

  const headers = [
    { label: "Medium Account Username", key: "mediumAccountUsername" },
    { label: "Title", key: "title" },
    { label: "Tags", key: "tags" },
    { label: "Url", key: "url" },
    { label: "Result", key: "result" },
    { label: "Action", key: "action" },
  ];

  const csvData = [
    {
      mediumAccountUsername: "Sporttrend",
      title: "Take My Hand - Jesus The Cross Lion Of Judah Hoodie - 3D Printed Hoodie - Christmas Gift",
      tags: "3D Hoodie Jesus",
      url: "https://medium.com/@teetrend/the-hand-of-jesus-lion-and-lamb-hoodie-jesus-is-my-savior-hoodies-jesus-hoodie-christmas-gift-6616c805904c",
      result: "",
      action: "john.doe@example.com",
    },
  ];

  return (
    <CustomListPage
      title="Medium Post"
      headerCard={
        <div className="flex-column">
          <div className="flex justify-end gap-2">
            <InsertPost />
            <CSVLink data={csvData} headers={headers} filename={"table.csv"}>
              <CustomButton title="Export CSV" icon={<SvgIcon.IconExport className="w-[18px]" />} type="primary" />
            </CSVLink>
          </div>
          <Row className="flex-center-between">
            <Col span={isWidthMobile ? 24 : 16} className={`${isWidthMobile ? "mb-2" : ""}`}>
              <CustomInput placeholder="Search medium post" prefix={<SearchOutlined />} />
            </Col>
            <Col span={isWidthMobile ? 24 : 4} className="flex justify-end gap-2">
              <CustomSelect options={optionsOrderby} width="100%" value={SORT_ORDER.DESC} onChange={(value) => {}} />
              <FilterWidget />
            </Col>
          </Row>
        </div>
      }
      contentCard={
        <>
          <CustomTable columns={columns} data={[{}]} rowKey={(record) => record?.id} />
          <CustomPagination total={0} limit={25} page={1} handleChangePage={() => {}} onChangePerPage={() => {}} />
        </>
      }
    />
  );
};
