import { Space, Tag, Typography } from "antd";
import { SvgIcon } from "../../../assets/icons";
import { CustomButton } from "../../../components/custom-button/CustomButton";
import { CustomPopconfirm } from "../../../components/custom-popconfirm/CustomPopconfirm";
import { copyText } from "../../../utils/helperFuncs";

export const useTable = () => {
  const columns = [
    {
      title: <Typography.Text>ID</Typography.Text>,
      key: "id",
      width: 100,
      align: "center",
      render: (_, record, index) => {
        return <Typography.Text>{index + 1}</Typography.Text>;
      },
    },
    {
      title: <Typography.Text>Medium Account</Typography.Text>,
      width: "15%",
      align: "center",
      key: "title",
      render: (_, record) => {
        return <Typography.Text>Sporttrend</Typography.Text>;
      },
    },
    {
      title: <Typography.Text>Title</Typography.Text>,
      key: "domain",
      render: (_, record) => {
        return (
          <Typography.Text>
            The Cross And The Praying Lion Jesus Focus On Me 3d Hoodies For Women Men - Christmas Gift
          </Typography.Text>
        );
      },
    },
    {
      title: <Typography.Text>Tags</Typography.Text>,
      key: "status",
      align: "center",
      width: "15%",
      render: (_, record) => {
        return <Tag color="geekblue">3D Hoodie Jesus</Tag>;
      },
    },
    {
      title: <Typography.Text>URL</Typography.Text>,
      key: "status",
      align: "center",
      render: (_, record) => {
        return (
          <CustomButton
            title="Copy URL"
            type="primary"
            icon={<SvgIcon.IconCopy className="w-5" />}
            onClick={() => {
              copyText(
                "https://medium.com/@teetrend/the-hand-of-jesus-lion-and-lamb-hoodie-jesus-is-my-savior-hoodies-jesus-hoodie-christmas-gift-6616c805904c"
              );
            }}
          />
        );
      },
    },
    {
      title: <Typography.Text>Created At</Typography.Text>,
      key: "status",
      width: 150,
      align: "center",
      render: (_, record) => {
        return "09:06:27 10/10/2024	";
      },
    },
    {
      key: "action",
      align: "center",
      width: 150,
      render: (_, record) => {
        return (
          <Space size="middle">
            <CustomPopconfirm
              title="Delete"
              component={<SvgIcon.IconDelete />}
              description="Are you sure to delete this?"
              onConfirm={() => {}}
            />
          </Space>
        );
      },
    },
  ];
  return { columns };
};
