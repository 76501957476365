import { SearchOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { CustomInput } from "../../../components/custom-input/CustomInput";
import { CustomPagination } from "../../../components/custom-pagination/CustomPagination";
import { CustomSelect } from "../../../components/custom-select/CustomSelect";
import { CustomTable } from "../../../components/custom-table/CustomTable";
import { useIsWidthMobile } from "../../../hooks/apps/useIsWidthMobile";
import { useList } from "../../../hooks/product-sync-task/list-product-sync-task/useList";
import { useTable } from "../../../hooks/product-sync-task/list-product-sync-task/useTable";
import { CustomListPage } from "../../../layouts/custom-list-page/CustomListPage";
import { optionsOrderby } from "../../../utils/options";
import { FilterWidget } from "./widget/FilterWidget";

export const ListProductSyncTaskSection = () => {
  const {
    dataProductSyncTask,
    paginationData,
    valuesSearch,
    loading,
    selectedRowKeys,
    paramsProductSyncTask,
    onSelectChange,
    setParamsProductSyncTask,
    handleSortOrder,
    handleSearchProductSyncTask,
    handleChangePerPage,
    handleChangePage,
  } = useList();

  const { columns } = useTable();
  const isWidthMobile = useIsWidthMobile();

  return (
    <CustomListPage
      title="Product Sync Task"
      headerCard={
        <div className="flex-column">
          <Row className="flex-center-between">
            <Col span={isWidthMobile ? 24 : 16} className={`${isWidthMobile ? "mb-2" : ""}`}>
              <CustomInput
                placeholder="Search product sync task"
                prefix={<SearchOutlined />}
                value={valuesSearch}
                onChange={(value) => handleSearchProductSyncTask(value)}
              />
            </Col>
            <Col span={isWidthMobile ? 24 : 4} className="flex justify-end gap-2">
              <CustomSelect
                options={optionsOrderby}
                value={paramsProductSyncTask.sort_order}
                width="100%"
                onChange={(value) => handleSortOrder(value)}
              />
              <FilterWidget paramsProductSyncTask={paramsProductSyncTask} setParamsProductSyncTask={setParamsProductSyncTask} />
            </Col>
          </Row>
        </div>
      }
      contentCard={
        <>
          <CustomTable
            columns={columns}
            data={dataProductSyncTask}
            rowKey={(record) => record?.id}
            rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }}
            loading={loading}
          />

          {dataProductSyncTask?.length > 0 && (
            <CustomPagination
              total={paginationData?.total}
              limit={paginationData?.limit}
              page={paginationData?.page}
              handleChangePage={(pageNumber) => handleChangePage(pageNumber)}
              onChangePerPage={(pageSize) => handleChangePerPage(pageSize)}
            />
          )}
        </>
      }
    />
  );
};
