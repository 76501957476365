import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getProductSyncTask } from "../../../services/productSyncTask";
import { PARAMS_DEFAULT } from "../../../utils/constant";
import { buildQueryString, getQuery, useDebounce } from "../../../utils/helperFuncs";
import { optionUseQuery } from "../../../utils/options";

export const useList = () => {
  const GET_QUERY = getQuery();

  const [loadingDelete, setLoadingDelete] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [valuesSearch, setValueSearch] = useState(GET_QUERY?.query ?? "");
  const debouncedQuery = useDebounce(valuesSearch);

  const [paramsProductSyncTask, setParamsProductSyncTask] = useState({
    ...PARAMS_DEFAULT,
    page: GET_QUERY.page ?? PARAMS_DEFAULT.page,
    per_page: GET_QUERY.per_page ?? PARAMS_DEFAULT.per_page,
    sort_order: GET_QUERY.sort_order ?? PARAMS_DEFAULT.sort_order,
    sort_by: GET_QUERY.sort_by ?? PARAMS_DEFAULT.sort_by,
    query: GET_QUERY?.query,
  });

  const { data, isFetching, refetch } = useQuery(
    ["getProductSyncTask", { id: 1283, params: paramsProductSyncTask }],
    ({ queryKey }) => getProductSyncTask(queryKey[1]),
    optionUseQuery
  );

  const handleSearchProductSyncTask = (event) => {
    setValueSearch(event?.target?.value);
  };

  const handleChangePerPage = (pageSize) => {
    setParamsProductSyncTask({ ...paramsProductSyncTask, per_page: pageSize, page: PARAMS_DEFAULT.page });
  };

  const handleChangePage = (pageNumber) => {
    setSelectedRowKeys([]);
    setParamsProductSyncTask({ ...paramsProductSyncTask, page: pageNumber });
  };

  const handleSortOrder = (value) => {
    setParamsProductSyncTask({ ...paramsProductSyncTask, sort_order: value, page: PARAMS_DEFAULT.page });
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  useEffect(() => {
    setParamsProductSyncTask({ ...paramsProductSyncTask, query: valuesSearch, page: PARAMS_DEFAULT.page });
  }, [debouncedQuery]);

  useEffect(() => {
    buildQueryString({
      params: paramsProductSyncTask,
    });
  }, [paramsProductSyncTask]);

  return {
    dataProductSyncTask: data?.salesChannelProductSyncTasks,
    paginationData: data?.paginationData,
    valuesSearch,
    loading: loadingDelete || isFetching,
    selectedRowKeys,
    paramsProductSyncTask,
    setParamsProductSyncTask,
    onSelectChange,
    handleSortOrder,
    handleSearchProductSyncTask,
    handleChangePerPage,
    handleChangePage,
  };
};
