import { UploadOutlined } from "@ant-design/icons";
import { Col, Form, Row, Upload } from "antd";
import { useState } from "react";
import { SvgIcon } from "../../../../assets/icons";
import { CustomButton } from "../../../../components/custom-button/CustomButton";
import { CustomInput } from "../../../../components/custom-input/CustomInput";
import CustomModal from "../../../../components/custom-modal/CustomModal";
import { notifyError, notifySuccess } from "../../../../components/custom-notify/CustomNotify";
import { CustomNameFormItem } from "../../../../components/custom-title/CustomNameFormItem";
import { useInsertPost } from "../../../../hooks/medium-post/list-medium-post/useInsertPost";
import { createSalesChannel } from "../../../../services/salesChannelService";
import { ACCEPT_FILE_TYPES_CSV } from "../../../../utils/constant";
import { checkRulesNotNull } from "../../../../utils/validator";

export const InsertPost = () => {
  const [visible, setVisible] = useState(false);
  const initialValues = {};
  const { form, fileList, refetch } = useInsertPost();

  const handleCreateSalesChannel = () => {
    form
      .validateFields()
      .then(() => {
        const formData = form.getFieldsValue();

        const createData = {
          domain: formData.domain,
          api_key: formData.api_key,
          api_token: formData.api_token,
          platform: formData.platform,
        };

        createSalesChannel(createData)
          .then((res) => {
            refetch();
            notifySuccess(res?.message);
            setVisible(false);
          })
          .catch((error) => {
            notifyError(error?.toString());
          });
      })
      .catch((error) => {});
  };
  return (
    <Form form={form} initialValues={initialValues}>
      <CustomModal
        component={
          <CustomButton
            title="Insert Post"
            icon={<SvgIcon.IconCreate />}
            type="primary"
            onClick={() => setVisible(true)}
          />
        }
        title="Insert Post"
        width={550}
        open={visible}
        onClose={() => {
          form.resetFields();
          setVisible(false);
        }}
        onCancel={() => {
          form.resetFields();
          setVisible(false);
        }}
        onOk={() => handleCreateSalesChannel()}
        labelConfirm="Create"
        content={
          <Col span={24} className="flex-column">
            <Row>
              <CustomNameFormItem title="Post Per Minute" required />
              <Form.Item name="domain" className="w-full" rules={[checkRulesNotNull()]}>
                <CustomInput placeholder="Enter post per minute" isNumber />
              </Form.Item>
            </Row>
            <Row>
              <CustomNameFormItem title="File Input" required />
              <Form.Item name="api_key" className="w-full" rules={[checkRulesNotNull()]}>
                <div className="w-full">
                  <Form.Item name="upload" className="w-full" rules={[checkRulesNotNull()]}>
                    <Upload
                      action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                      accept={ACCEPT_FILE_TYPES_CSV}
                      fileList={fileList}
                      onChange={(value) => {}}
                      maxCount={1}
                    >
                      <CustomButton icon={<UploadOutlined />} title="Choose file..." />
                    </Upload>
                  </Form.Item>
                </div>
              </Form.Item>
            </Row>
          </Col>
        }
      />
    </Form>
  );
};
