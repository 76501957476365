/* eslint-disable react-hooks/exhaustive-deps */
import { Layout } from "antd";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.scss";
import { useApp } from "./hooks/apps/useApp";
import { useIsWidthMobile } from "./hooks/apps/useIsWidthMobile";
import { MenuMobile } from "./layouts/menu-mobile/menu-mobile";
import { NavBar } from "./layouts/navbar/navbar";
import { SideBar } from "./layouts/sidebar/SideBar";
import { VersionManager } from "./layouts/version-manager/VersionManager";
import { ChangePasswordPage } from "./pages/ChangePassword";
import { ErrorPage } from "./pages/ErrorPage";
import { HomePage } from "./pages/Home";
import { LoginPage } from "./pages/LoginPage";
import { PersonalInfoPage } from "./pages/PersonalInfo";
import { CategoriesPages } from "./pages/categories/CategoriesPage";
import { CategoryDetailPage } from "./pages/categories/CategoryDetail";
import { CreateDescriptionPage } from "./pages/description/CreateDescription";
import { DetailDescriptionPage } from "./pages/description/DetailDescription";
import { DescriptionsPage } from "./pages/description/ListDescriptions";
import { ListMediumAccount } from "./pages/medium-account/ListMediumAccount";
import { ListMediumPost } from "./pages/medium-post/ListMediumPost";
import { DetailProductSyncTask } from "./pages/product-sync-task/DetailProductSyncTask";
import { ListProductSyncTask } from "./pages/product-sync-task/ListProductSyncTask";
import { ProductsPages } from "./pages/products/ListProduct";
import { UploadProductPage } from "./pages/products/UploadProduct";
import { DetailSalesChannelPage } from "./pages/sales-channel/DetailSalesChannel";
import { ListSalesChannelPage } from "./pages/sales-channel/ListSalesChannel";
import { DetailSyncTaskPage } from "./pages/sync-task/DetailSyncTask";
import { ListSyncTaskPage } from "./pages/sync-task/ListSyncTask";
import { CreateUsersPage } from "./pages/users/CreateUsers";
import { DetailUserPage } from "./pages/users/DetailUser";
import { ListUserPage } from "./pages/users/ListUsers";
import { getToken } from "./services/authService";
import { isAllRoles } from "./utils/boolean";

const { Content } = Layout;

const MainLayout = () => {
  const token = getToken();
  const decodedToken = jwtDecode(token);
  const { collapsed, setCollapsed } = useApp();
  const isWidthMobile = useIsWidthMobile();

  useEffect(() => {
    document.documentElement.style.setProperty("--padding-title-menu", collapsed ? "6px" : "28px 16px");
  }, [JSON.stringify(collapsed)]);

  useEffect(() => {
    console.clear();
  }, []);

  const clearConsoleOnEvent = (event) => {
    console.clear();
  };

  window.addEventListener("click", clearConsoleOnEvent);

  return (
    <Layout className="layout-page">
      <SideBar collapsed={collapsed} setCollapsed={setCollapsed} />
      <Layout>
        <NavBar collapsed={collapsed} setCollapsed={setCollapsed} />
        <Content
          className={`content-layout-page mt-[60px] transition-all duration-[250ms] p-${isWidthMobile ? 3 : 6} ${
            collapsed ? (!isWidthMobile ? "ml-[80px]" : "ml-[0px]") : "ml-[300px]"
          }`}
        >
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/products" element={<ProductsPages />} />
            <Route path="/products/upload" element={<UploadProductPage />} />
            <Route path="/descriptions" element={<DescriptionsPage />} />
            <Route path="/descriptions/:id" element={<DetailDescriptionPage />} />
            <Route path="/descriptions/create" element={<CreateDescriptionPage />} />
            <Route path="/categories" element={<CategoriesPages />} />
            <Route path="/categories/:id" element={<CategoryDetailPage />} />
            <Route path="/sales-channel" element={<ListSalesChannelPage />} />
            <Route path="/sales-channel/:id" element={<DetailSalesChannelPage />} />
            <Route path="/sync-task" element={<ListSyncTaskPage />} />
            <Route path="/sync-task/:id" element={<DetailSyncTaskPage />} />
            <Route path="/medium-post" element={<ListMediumPost />} />
            <Route path="/medium-account" element={<ListMediumAccount />} />
            <Route path="/product-sync-task" element={<ListProductSyncTask />} />
            <Route path="/product-sync-task/:id" element={<DetailProductSyncTask />} />

            <Route
              path="/users"
              element={isAllRoles(decodedToken.role) ? <ListUserPage /> : <Navigate to="/error" />}
            />
            <Route
              path="/users/:id"
              element={isAllRoles(decodedToken.role) ? <DetailUserPage /> : <Navigate to="/error" />}
            />
            <Route
              path="/users/create"
              element={isAllRoles(decodedToken.role) ? <CreateUsersPage /> : <Navigate to="/error" />}
            />
            <Route path="/personal-info/:id" element={<PersonalInfoPage />} />
            <Route path="/change-password/:id" element={<ChangePasswordPage />} />
            <Route path="*" element={<Navigate to="/error" replace />} />
          </Routes>
        </Content>
        <div className="menu-mobile">{isWidthMobile && <MenuMobile />}</div>
      </Layout>
      <VersionManager />
    </Layout>
  );
};

const App = () => {
  const token = getToken();
  const [isAuthenticated, setIsAuthenticated] = useState(!!token);

  useEffect(() => {
    setIsAuthenticated(!!token);
  }, [token]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage setIsAuthenticated={setIsAuthenticated} />} />
        <Route path="/*" element={isAuthenticated ? <MainLayout /> : <Navigate to="/login" replace />} />
        <Route path="/error" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
