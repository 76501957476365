/* eslint-disable react-hooks/exhaustive-deps */
import { Form } from "antd";
import { useState } from "react";
import { PARAMS_DEFAULT } from "../../../utils/constant";
import { getQuery } from "../../../utils/helperFuncs";

export const useFilter = ({ paramsProductSyncTask, setParamsProductSyncTask }) => {
  const GET_QUERY = getQuery();
  const [form] = Form.useForm();
  const [visiblePopover, setVisiblePopover] = useState(false);

  const initialValues = {
    status: paramsProductSyncTask?.status,
  };

  const handleClearFilter = () => {
    setVisiblePopover(false);
    setParamsProductSyncTask({
      ...PARAMS_DEFAULT,
      query: paramsProductSyncTask?.query,
      sort_order: paramsProductSyncTask?.sort_order,
      sort_by: paramsProductSyncTask?.sort_by,
      page: PARAMS_DEFAULT.page,
    });

    form.setFieldsValue({
      status: undefined,
    });
  };

  const handleFilter = () => {
    const formValue = form.getFieldsValue();
    setParamsProductSyncTask({
      ...paramsProductSyncTask,
      status: formValue?.status ?? GET_QUERY.status,
    });
    setVisiblePopover(false);
  };

  return {
    form,
    visiblePopover,
    initialValues,
    handleClearFilter,
    handleFilter,
    setVisiblePopover,
  };
};
