import api from "./api";
import { headersRequest } from "./authService";

// fetch sales channel
export const getSalesChannels = async (paramsSalesChannel) => {
  try {
    const response = await api.get(`/sales-channels`, {
      params: paramsSalesChannel,
      headers: headersRequest(),
    });
    const data = response?.data?.data;
    return {
      salesChannels: data?.sales_channels,
      paginationData: { total: data?.total, limit: data?.limit, page: data?.page },
    };
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error fetching sales channels");
  }
};

// fetch sales channel detail
export const getSalesChannelDetail = async (id) => {
  try {
    const response = await api.get(`/sales-channels/${id}`, {
      headers: headersRequest(),
    });

    return response?.data?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error fetching sales channels");
  }
};

// update sales channel
export const updateSalesChannel = async (id, updateData) => {
  try {
    const response = await api.put(`/sales-channels/${id}`, updateData, {
      headers: headersRequest(),
    });
    return response?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error updating sales channel");
  }
};

// delete sales channel
export const deleteSalesChannel = async (salesChanelId) => {
  try {
    const response = await api.delete(`/sales-channels/${salesChanelId}`, {
      headers: headersRequest(),
    });
    return response?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error fetching sales channels");
  }
};

// get sales channel sync tags
export const getSalesChannelSyncTags = async (request) => {
  try {
    const response = await api.get(`/sales-channels/${request?.id}/sync/tags`, {
      params: request?.params,
      headers: headersRequest(),
    });
    return response?.data?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error get sales channel sync tags");
  }
};

// get sales channel sync tags
export const getSalesChannelSyncCategories = async (request) => {
  try {
    const response = await api.get(`/sales-channels/${request?.id}/sync/categories`, {
      params: request?.params,
      headers: headersRequest(),
    });
    return response?.data?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error get sales channel sync tags");
  }
};

// delete bulk sales channels
export const deleteBulkSalesChannel = async (requestDelete) => {
  try {
    const response = await api.delete(`/sales-channels/bulk-delete`, {
      headers: headersRequest(),
      data: requestDelete,
    });
    return response?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error delete bulk sales channels");
  }
};

// create sales channel
export const createSalesChannel = async (createData) => {
  try {
    const response = await api.post(`/sales-channels`, createData, {
      headers: headersRequest(),
    });
    return response?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error create sales channel");
  }
};
