import { Form, Row } from "antd";
import { Link } from "react-router-dom";
import { CustomButton } from "../../../components/custom-button/CustomButton";
import { CustomInput } from "../../../components/custom-input/CustomInput";
import { CustomSelect } from "../../../components/custom-select/CustomSelect";
import { CustomNameFormItem } from "../../../components/custom-title/CustomNameFormItem";
import { CustomUploadAvatar } from "../../../components/custom-upload-avatar/CustomUploadAvartar";
import { useDetail } from "../../../hooks/product-sync-task/detail-product-sync-task/useDetail";
import { CustomDetailPage } from "../../../layouts/custom-detail-page/CustomDetailPage";
import { BlockCard } from "../../../themes/block-card/BlockCard";
import { optionsProductSyncTaskStatus, optionsStatusScProduct } from "../../../utils/options";
import { checkRulesNotNull } from "../../../utils/validator";

export const DetailProductSyncTaskSection = () => {
  const { form } = useDetail();

  return (
    <Form form={form}>
      <CustomDetailPage
        title="Product Sync Task Detail"
        isMoreColumn
        actionButton={
          <>
            <Link to={"/sales-channel"}>
              <CustomButton title="Back" type="default" />
            </Link>
          </>
        }
        contentLeft={
          <BlockCard
            content={
              <>
                <Row>
                  <CustomNameFormItem title="Name" required />
                  <Form.Item name="domain" className="w-full" rules={[checkRulesNotNull()]}>
                    <CustomInput placeholder="Enter Domain" />
                  </Form.Item>
                </Row>
                <Row>
                  <CustomNameFormItem title="Sales Channel" required />
                  <Form.Item name="domain" className="w-full" rules={[checkRulesNotNull()]}>
                    <CustomInput placeholder="Enter Domain" />
                  </Form.Item>
                </Row>
                <Row style={{ display: "flex", gap: "2%" }}>
                  <div style={{ width: "49%" }}>
                    <CustomNameFormItem title="External" required />
                    <Form.Item name="api_token" className="w-full" rules={[checkRulesNotNull()]}>
                      <CustomInput placeholder="Enter Api Token" />
                    </Form.Item>
                  </div>
                  <div style={{ width: "49%" }}>
                    <CustomNameFormItem title="Publish Schedule" required />
                    <Form.Item name="api_key" className="w-full" rules={[checkRulesNotNull()]}>
                      <CustomInput placeholder="Enter Api key" />
                    </Form.Item>
                  </div>
                </Row>
              </>
            }
          />
        }
        contentRight={
          <BlockCard
            content={
              <>
                <Row>
                  <CustomNameFormItem title="Thumb" required />
                  <Form.Item name="platform" className="w-full" rules={[checkRulesNotNull()]}>
                    <CustomUploadAvatar />
                  </Form.Item>
                </Row>
                <Row>
                  <CustomNameFormItem title="Status" required />
                  <Form.Item name="platform" className="w-full" rules={[checkRulesNotNull()]}>
                    <CustomSelect placeholder="Enter Status" options={optionsStatusScProduct} width="100%" />
                  </Form.Item>
                </Row>
                <Row>
                  <CustomNameFormItem title="Active" required />
                  <Form.Item name="status" className="w-full" rules={[checkRulesNotNull()]}>
                    <CustomSelect placeholder="Enter Active" options={optionsProductSyncTaskStatus} width="100%" />
                  </Form.Item>
                </Row>
              </>
            }
          />
        }
      />
    </Form>
  );
};
