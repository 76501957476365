import api from "./api";
import { headersRequest } from "./authService";

// fetch product sync task
export const getProductSyncTask = async (request) => {
  try {
    const response = await api.get(`/sales-channel-sync-tasks/${request?.id}/sc_product_sync_tasks`, {
      params: request?.params,
      headers: headersRequest(),
    });
    const data = response?.data?.data;
    return {
      salesChannelProductSyncTasks: data?.salesChannelProductSyncTasks,
      paginationData: { total: data?.total, limit: data?.limit, page: data?.page },
    };
  } catch (error) {
    throw new Error(error.response?.data?.error);
  }
};
