import { Form, Row } from "antd";
import { SvgIcon } from "../../../../assets/icons";
import { CustomButton } from "../../../../components/custom-button/CustomButton";
import { CustomPopover } from "../../../../components/custom-popover/CustomPopover";
import { CustomSelectTag } from "../../../../components/custom-select-tag/CustomSelectTag";
import { CustomNameFormItem } from "../../../../components/custom-title/CustomNameFormItem";
import { useIsWidthMobile } from "../../../../hooks/apps/useIsWidthMobile";
import { useFilter } from "../../../../hooks/product-sync-task/list-product-sync-task/useFilter";
import { optionsStatusScProduct } from "../../../../utils/options";

export const FilterWidget = ({ paramsProductSyncTask, setParamsProductSyncTask }) => {
  const { form, visiblePopover, initialValues, handleClearFilter, handleFilter, setVisiblePopover } = useFilter({
    paramsProductSyncTask,
    setParamsProductSyncTask,
  });
  const isWidthMobile = useIsWidthMobile();

  return (
    <Form form={form} initialValues={initialValues}>
      <CustomPopover
        width={isWidthMobile ? 300 : 400}
        open={visiblePopover}
        onClose={() => setVisiblePopover(false)}
        component={
          <CustomButton
            title="Filter"
            type="default"
            onClick={() => {
              setVisiblePopover(true);
            }}
            icon={<SvgIcon.IconFilter className="w-5 text-[#4361ee]"></SvgIcon.IconFilter>}
          />
        }
        title={
          <>
            <div>Filter</div>
            <div className="flex gap-2">
              <CustomButton title="Clear" height={32} onClick={() => handleClearFilter()} />
              <CustomButton title="Save" type="primary" height={32} onClick={() => handleFilter()} />
            </div>
          </>
        }
        content={
          <Row>
            <CustomNameFormItem title="Filter status sync products" />
            <Form.Item name="status" className="w-full">
              <CustomSelectTag
                placeholder="Filter status sync products"
                options={optionsStatusScProduct}
                showSearch
                onSearch={(value) => {}}
                onDropdownVisibleChange={(open) => {}}
                filterOption={false}
              />
            </Form.Item>
          </Row>
        }
      />
    </Form>
  );
};
